.subServiceItem {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr max-content;
  font-size: 1.3rem;
  font-weight: 500;
  border-bottom: 1px solid #d8d8d8;
  padding: 0.2rem 0;
}
.subServiceTitle {
  font-weight: 600;
}
.subServiceDuration {
}
.subServicePrice {
}
.subServiceBtn {
  background-color: transparent;
  border: none;
  color: #019267;
  padding: 0.2rem;
}
