.WhyUs {
  background-color: #116d6e;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  padding: 6rem;
  margin-top: 3rem;
  text-align: center;
  color: #fff;
}

.WhyUs h1 {
  font-weight: 400;
  font-family: 'Josefin Sans', sans-serif;
}

.WhyUsBrief {
  font-size: 1.5rem;
}
.WhyUsGlance {
  display: grid;
  grid-template-columns: repeat(auto-fit, 20rem);
  gap: 10rem;
  justify-content: center;
  justify-items: center;
  margin-top: 2rem;
}

.WhyUsGlanceItem {
  display: grid;
  gap: 0.5rem;
  justify-items: center;
}

.WhyUsGlanceItem img {
  width: 40%;
}

.WhyUsGlanceItem h5 {
  font-size: 1.5rem;
}

.WhyUsGlanceItem p {
  font-size: 1.2rem;
}

.WhyUsDescription {
  font-size: 1.35rem;
  line-height: 1.8;
  width: 70%;
  margin: 2.5rem auto 0 auto;
}
