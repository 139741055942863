.buss_services {
  margin: 3rem;
  padding: 2rem;
  box-shadow: 0px 0px 10px rgb(231, 231, 231);
  border-radius: 15px;
  display: grid;
  gap: 3rem;
}
.buss_services h1 {
  color: #116d6e;
  text-align: center;
}

.bus_srv_inputs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
}

.bus_srv_inputDivs {
  display: grid;
  gap: 0.5rem;
}
.bus_srv_inputDivs label {
  font-size: 1.5rem;
  font-weight: 500;
}
.bus_srv_inputDivs input,
.bus_srv_inputDivs select {
  padding: 1.5rem;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
}
.bus_srv_inputDivs input:focus,
.bus_srv_inputDivs input:active,
.bus_srv_inputDivs select:focus,
.bus_srv_inputDivs select:active {
  outline: none;
  border-bottom: 3px solid #116d6e;
}

.bus_srv_inputDivs input::placeholder {
  font-size: 1.5rem;
  color: #5d8191;
}

.bus_srv_Btn {
  grid-row: 4 / 5;
  padding: 1rem 4rem;
  font-size: 1.6rem;
  background-color: #116d6e;
  color: #fff;
  border-radius: 6px;
  border: none;
  width: max-content;
  place-self: start;
  position: relative;
}
.bus_srv_Btn:hover {
  background-color: #28b5b5;
  cursor: pointer;
}
.bus_srv_Btn span {
  position: absolute;
  left: 15rem;
  top: 0;
  color: #000000;
  font-size: 1.5rem;
  width: 100%;
}

.bus_Srv_ActivList {
  display: grid;
  gap: 2rem;
  border: 1px dashed #116d6e;
  border-radius: 10px;
  padding: 4rem 2rem;
}
.bus_Srv_ActivList_Item {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 5px rgb(204, 204, 204);
  border-radius: 5px;
  padding: 1rem 1.5rem;
  font-size: 1.3rem;
  font-weight: 500;
}
.bus_Srv_ItemDetails {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 15rem);
}
.bus_Srv_ItemDetails p {
  border-right: 1px solid rgb(202, 202, 202);
  padding-left: 1.5rem;
}
.bus_Srv_ItemActions {
  display: flex;
  justify-content: space-between;
}

.bus_Srv_ItemActions span {
  font-size: 1.8rem;
  padding: 0 1rem;
  cursor: pointer;
}
.bus_Srv_Edit {
  color: #28b5b5;
}
.bus_Srv_Edit:hover {
  border-bottom: 2px solid #28b5b5;
}
.bus_Srv_Del {
  color: #cd1818;
}
.bus_Srv_Del:hover {
  border-bottom: 2px solid #cd1818;
}

.inputErrorMsg {
  font-size: 1.3rem;
  grid-column: 1 / -1;
  color: #cd1818;
  font-weight: 600;
}

@media only screen and (max-width: 1000px) {
  .bus_srv_inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
  }
}
@media only screen and (max-width: 560px) {
  .bus_srv_inputs {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
  }
}
