.serviceListItem {
  display: grid;
  gap: 0.5rem;
  column-gap: 1rem;
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: min-content min-content;
  background-color: #fff8e1;
  padding: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0px 0px 5px rgb(219, 219, 219);
  border-radius: 5px;
  cursor: pointer;
}
.serviceListItemIcon {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  width: 4.5rem;
}
.serviceListItemTitle {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  font-size: 1.5rem;
  font-weight: 500;
}
.serviceListItemSubs {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  font-size: 1.2rem;
}
.dropdown {
  grid-column: 3 / 4;
  grid-row: 1 / 3;
  width: 4rem;
}

.subService {
  list-style: none;
  display: grid;
  row-gap: 0.5rem;
  background-color: #f5f5f5;
  padding: 2rem;
  margin-bottom: 1rem;
}
