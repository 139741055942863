.login {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  transform: translateY(-5.7rem);
}

.loginForm {
  width: max-content;
  height: max-content;
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  justify-items: center;
  gap: 2rem;
  box-shadow: 0px 0px 10px rgb(199, 199, 199);
  background-color: #fff;
  border-radius: 20px;
  padding: 5rem;
}

.loginInputs {
  padding: 1.5rem;
  width: 30rem;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
}
.loginInputs:focus,
.loginInputs:active {
  outline: none;
  border-bottom: 3px solid #116d6e;
}

.loginInputs::placeholder {
  font-size: 1.5rem;
  color: #5d8191;
}
.loginBtns {
  padding: 1rem 4rem;
  font-size: 1.6rem;
  background-color: #116d6e;
  color: #fff;
  border-radius: 6px;
  border: none;
}

.loginBtns:hover {
  background-color: #28b5b5;
  cursor: pointer;
  font-weight: 600;
}

.loginClose {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  color: #cd1818;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 2rem;
}
.login_forgotPass_btn {
  font-size: 1.3rem;
  font-weight: 500;
  color: #116d6e;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.login_signUp {
  font-size: 1.4rem;
  font-weight: 500;
  text-decoration: none;
  color: #000;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.loginErrors {
  font-size: 1.4rem;
  font-weight: 600;
  color: #cd1818;
}
@media only screen and (max-width: 465px) {
  .loginInputs {
    padding: 1.5rem;
    width: 20rem;
    border-radius: 5px;
    border: 1px solid #dfdfdf;
  }
}
