.custAcc {
  display: grid;
  grid-template-columns: 1.2fr 5fr;
}
.custAcc_Left {
  grid-column: 1 / 2;
  background-color: #ececec;
  min-height: 90vh;
  max-height: 100%;
}
.custAcc_Left ul {
  list-style: none;
  padding: 1rem;
  display: grid;
  gap: 1rem;
}
.custAcc_Left ul li {
  background-color: #fff;
  padding: 1rem;
  width: 90%;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgb(194, 194, 194);
}
.custAcc_Left ul li a {
  text-decoration: none;
  font-size: 1.5rem;
  color: #116d6e;
  font-weight: 600;
}
.custAcc_Left_btn {
  font-size: 1.5rem;
  color: #116d6e;
  font-weight: 600;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.custAcc_Right {
  grid-column: 2 / 3;
}
@media only screen and (max-width: 1000px) {
  .custAcc {
    display: grid;
    gap: 0;
    grid-template-columns: 1fr;
    padding-top: 8rem;
  }
  .custAcc_Left {
    grid-column: 1 / -1;
    background-color: #ececec;
    min-height: max-content;
    max-height: max-content;
  }
  .custAcc_Left ul {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, 15rem);
  }
  .custAcc_Left ul li {
    padding: 0.5rem;
    width: 100%;
    margin: 0;
  }
  .custAcc_Right {
    grid-column: 1 / -1;
  }
}
