.mainNav {
  display: grid;
  grid-template-columns: min-content 1fr 1fr;
  box-shadow: 0px 1px 5px rgb(230, 230, 230);
}

.logo {
  grid-column: 1 / 2;
  width: 20rem;
  margin: 1rem 2rem;
  cursor: pointer;
}

.menuListBox {
  grid-column: 3 / 4;
  place-self: center start;
  width: 100%;
}
.menuList {
  display: flex;
  justify-content: space-around;
}

.menuListItem {
  list-style: none;
  padding: 0.5rem;
}

.menuListItemAnchor {
  font-size: 1.4rem;
  font-weight: 500;
  text-decoration: none;
  color: #083a56;
}
.menuListItemAnchor span {
  font-size: 2rem;
}

.activNav {
  color: #068d33;
}

/* Mini Navigation */
.miniNav {
  position: absolute;
  top: 3rem;
  right: 2rem;
  width: 5rem;
  z-index: 100;
}

.miniNavCheck {
  display: none;
  position: relative;
}

.miniNavLogo {
  position: absolute;
  left: 0;
  top: 0;
  padding: 1rem;
  width: 100vw;
  background-color: #fff;
}
.miniNavLogo img {
  width: 20rem;
  cursor: pointer;
}

.miniNavChkLabel {
  background-color: transparent;
  width: 5rem;
  height: 3rem;
  position: relative;
  cursor: pointer;
  z-index: 200;
  display: block;
  padding: 0.9rem;
}

.miniNavChkLabel span,
.miniNavChkLabel span::after,
.miniNavChkLabel span::before {
  transition: all 0.3s;
  width: 3rem;
  height: 3px;
  background-color: #ffffff;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0.8rem;
  z-index: 200;
}

.miniNavChkLabel span::after,
.miniNavChkLabel span::before {
  content: '';
  display: block;
  position: absolute;
}

.miniNavChkLabel span::after {
  top: -1rem;
  left: 0rem;
}

.miniNavChkLabel span::before {
  top: 1rem;
  left: 0rem;
}

.miniNavBackground {
  transition: all 0.8s;
  position: absolute;
  top: -1.6rem;
  left: 0.4rem;
  width: 4rem;
  height: 3.8rem;
  background-color: #116d6e;
  border-radius: 2px;
}

.miniMenu {
  transition: all 0.8s;
  position: relative;
  top: 5rem;
  right: 12rem;
  z-index: 200;
  opacity: 0;
  visibility: hidden;
  width: max-content;
}
.miniMenuList {
  list-style: none;
  text-align: right;
  margin-bottom: 1.5rem;
}
.miniMenuListAnchor {
  color: #ffffff;
  text-decoration: none;
  font-size: 1.4rem;
}

.miniNavCheck:checked ~ .miniNavBackground {
  transform: scale(100);
}

.miniNavCheck:checked ~ .miniMenu {
  opacity: 1;
  visibility: visible;
}

.miniNavCheck:checked + .miniNavChkLabel span::after {
  transform: rotate(-135deg);
  top: 0rem;
}

.miniNavCheck:checked + .miniNavChkLabel span::before {
  transform: rotate(135deg);
  top: 0rem;
}

.miniNavCheck:checked + .miniNavChkLabel span {
  background-color: transparent;
}

@media only screen and (min-width: 1026px) {
  .miniNav {
    display: none;
  }
  .miniNavLogo {
    display: none;
  }
}
@media only screen and (max-width: 1025px) {
  .mainNav {
    display: none;
  }
}
@media only screen and (max-width: 1400px) {
  .mainNav {
    grid-template-columns: min-content 1fr 1.5fr;
  }
}
@media only screen and (max-width: 1200px) {
  .mainNav {
    grid-template-columns: min-content 1fr 2fr;
  }
}
@media only screen and (max-width: 1100px) {
  .mainNav {
    grid-template-columns: min-content 1fr 2.2fr;
  }
}
