.faq {
  margin: 4rem;
  display: grid;
  gap: 3rem;
}
.faq h1 {
  color: #116d6e;
}
.faq_box {
  display: grid;
  gap: 2.3rem;
  box-shadow: 0px 0px 10px rgb(226, 226, 226);
  border-radius: 15px;
  padding: 2rem;
}
.faq_subjects {
  list-style: none;
  display: grid;
  gap: 1.2rem;
}
.faq_subject_title {
  font-size: 1.5rem;
  color: #083a56;
  padding: 0.5rem;
  border-bottom: 1px solid rgb(206, 206, 206);
}
.faq_sub_item {
  font-size: 1.3rem;
  display: grid;
  gap: 0.6rem;
  font-weight: 500;
}
.faq_sub_item_que {
}
.faq_sub_item_ans {
  color: #116d6e;
}
