.hero {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  grid-template-rows: min-content;
}

.img {
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  width: 100%;
  height: 85vh;
  object-fit: cover;
}

.caption {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  font-size: 8rem;
  color: #fff;
  place-self: center start;
  padding: 3rem;
  text-shadow: 0px 0px 10px rgb(0, 0, 0);
}

@media only screen and (max-width: 1200px) {
  .caption {
    font-size: 6rem;
  }
}

@media only screen and (max-width: 1024px) {
  .caption {
    padding: 2rem;
  }
}

@media only screen and (max-width: 780px) {
  .hero {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
  }

  .img {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .caption {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    font-size: 5rem;
    place-self: center;
    padding: 1rem;
  }
}
