.blog {
  display: grid;
  grid-template-columns: 1fr 7fr;
  margin: 4rem;
}

.author {
  display: grid;
  gap: 1rem;
  justify-items: center;
  padding: 2rem;
  grid-template-rows: min-content min-content;
}

.author img {
  width: 100%;
  border-radius: 50%;
}
.author p {
  font-weight: 600;
  font-size: 1.35rem;
}

.info {
  padding: 4rem;
  box-shadow: 0px 0px 10px rgb(180, 180, 180);
  margin: 4rem;
  border-radius: 25px;
  width: 80%;
}

.info h1 {
  color: #116d6e;
  border-bottom: 1px solid rgb(165, 138, 16);
  padding: 0.2rem;
}

.info p {
  font-size: 1.6rem;
  line-height: 2;
  padding: 1rem 0;
}

@media only screen and (max-width: 1025px) {
  .blog {
    display: grid;
    grid-template-columns: 1fr;
    margin: 1.5rem;
  }
  .author img {
    width: 20%;
    margin-top: 8rem;
  }
}

@media only screen and (max-width: 800px) {
  .info {
    padding: 2rem;
    margin: 0 auto;
    border-radius: 25px;
  }
}
