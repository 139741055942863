.business_Appoint {
  box-shadow: 0px 0px 10px rgb(231, 231, 231);
  border-radius: 15px;
  width: 75vw;
  margin: 3rem auto;
  padding: 2rem;
}
.business_Appoint h1 {
  text-align: center;
  margin: 2rem 0;
  color: #116d6e;
}
.business_Appoint_List {
  display: grid;
  gap: 2rem;
}
