.blogList {
  color: #116d6e;
  padding: 6rem;
}

.blogList_head {
  border-bottom: 1px solid #116d6e;
}

.blogList_head_list {
  padding: 5rem;
  list-style: none;
  display: grid;
  gap: 4rem;
  grid-template-columns: repeat(auto-fill, 33rem);
  justify-content: center;
}
