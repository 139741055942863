.appointments {
  padding: 4rem;
}

/* Top navigator */
.apnt_switches {
  display: grid;
  grid-template-columns: repeat(2, minmax(10rem, 30rem));
  grid-template-rows: min-content min-content;
  justify-content: center;
  justify-items: center;
}
.apnt_swt_line {
  grid-column: 1 / -1;
  border-bottom: 2px solid rgb(235, 235, 235);
  width: 55%;
  transform: translateY(2rem);
  z-index: -1;
}
.apnt_switch {
  position: relative;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(224, 224, 224);
  cursor: pointer;
}

.apnt_switch span {
  position: absolute;
  text-align: center;
  width: 10rem;
  top: -2.5rem;
  left: -3rem;
}

.apnt_switch::after {
  display: block;
  content: '';
  position: absolute;
  top: 1.2rem;
  left: 1.2rem;
  width: 1.3rem;
  height: 1.3rem;
  background-color: #dddddd;
  border-radius: 50%;
}

.apnt_switch_active::after {
  background-color: #116d6e;
}
.appnt_error {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  color: red;
}
