.contact {
  margin: 4rem;
  display: grid;
  gap: 3rem;
  justify-items: center;
}
.contact h1 {
  text-align: center;
  color: #116d6e;
}
.contact_sub {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  width: 70%;
  border-radius: 20px;
  box-shadow: 0px 0px 7px rgb(117, 117, 117);
}
.contact_sub img {
  width: 100%;
  height: 35rem;
  object-fit: cover;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.contact_sub_details {
  padding: 3rem;
  display: grid;
  gap: 1rem;
  grid-template-rows: repeat(4, min-content);
  color: #116d6e;
}
.contact_sub_details input {
  padding: 1.5rem;
  width: 30rem;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
}
.contact_sub_details input:focus,
.contact_sub_details input:active {
  outline: none;
  border-bottom: 3px solid #116d6e;
}

.contact_sub_details input::placeholder {
  font-size: 1.5rem;
  color: #5d8191;
}
.contact_sub_details p {
  font-size: 1.4rem;
  font-weight: 500;
}
.contact_icons {
  transform: translateY(0.3rem);
}

@media only screen and (max-width: 1024px) {
  .contact {
    padding-top: 8rem;
    margin: 1rem;
  }
  .contact_sub {
    display: grid;
    grid-template-columns: 1fr;
  }
  .contact_sub img {
    width: 100%;
    height: 25rem;
    object-fit: cover;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
