.cust_Appoint_Item {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr max-content;
  box-shadow: 0px 0px 5px rgb(184, 184, 184);
  padding: 1rem;
  border-radius: 15px;
}
.cust_Apnt_Itm_head {
  display: grid;
  gap: 2rem;
  grid-template-columns: min-content repeat(3, 1fr);
  align-items: center;
  font-size: 1.4rem;
}
.c_A_I_head_serial {
  width: 2.5rem;
  height: 2.5rem;
  color: #083a56;
  font-size: 1.5rem;
  border-radius: 50%;
  padding: 0.1rem;
  text-align: center;
  font-weight: 700;
  border-right: 1px dashed #083a56;
}
.c_A_I_head_serial_div {
  display: grid;
  gap: 0.5rem;
}
.c_A_I_head_serial_div p {
  color: #083a56;
  font-weight: 500;
  margin-right: 1rem;
}
.c_A_I_head_serial_div h4 {
  color: #116d6e;
}
.custAcc_Apnt_Itm_btn {
  width: fit-content;
  background-color: #083a56;
  color: #fff;
  padding: 0.2rem 2rem;
  border-radius: 5px;
  border: none;
}
.customer_Apnt_Itm_details {
  grid-column: 1 / -1;
  border-top: 1px solid #116d6e;
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
  font-size: 1.3rem;
  font-weight: 500;
  background-color: #f8ffe8;
}
.customer_Apnt_Itm_dtls_L {
  display: grid;
  gap: 0.5rem;
}
.cust_Apnt_Itm_dtls_L_div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed rgb(221, 221, 221);
  padding: 0.3rem 0;
}
.customer_Apnt_Itm_dtls_R {
  display: grid;
  gap: 0.5rem;
}
.cust_Apnt_Itm_dtls_R_div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed rgb(221, 221, 221);
  padding: 0.3rem 0;
}
.cust_Apnt_Itm_dtls_R_div_BTN {
  background-color: #116d6e;
  color: #fff;
  padding: 0.7rem 1rem;
  border: none;
  border-radius: 5px;
  width: fit-content;
  place-self: end;
  cursor: pointer;
}
.cust_feedbck_backdrop {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 300;
  background-color: rgb(255, 255, 255, 0.5);
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
  display: grid;
  justify-items: center;
  align-items: center;
}
.cust_feedbck {
  transform: translateY(-15%);
  background-color: #fff;
  padding: 4rem;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgb(148, 148, 148);
  width: 40%;
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 0.5rem;
}
.cust_feedbck h1 {
  color: #116d6e;
  text-align: center;
}
.cust_feedbckDiv {
  grid-column: 1 / -1;
  display: grid;
  gap: 0.5rem;
}
.cust_feedbckDiv label {
  font-size: 1.5rem;
  font-weight: 500;
}
.cust_feedbckDiv textarea {
  padding: 1.5rem;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
}

.cust_feedbckDiv textarea:focus,
.cust_feedbckDiv textarea:active {
  outline: none;
  border-bottom: 3px solid #116d6e;
}
.cust_feedbckDiv_stars {
  display: grid;
  gap: 2rem;
  grid-template-columns: max-content 1fr;
  align-items: center;
}
.cust_feedbckDiv_stars label {
  font-size: 1.5rem;
  font-weight: 500;
}
.cust_feedback_starBox {
  display: grid;
  grid-template-columns: max-content;
  grid-template-rows: min-content;
  align-items: center;
}
.cust_fbdk_starPopulate {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}
.cust_fbdk_starBtns {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}
.cust_stars_btn {
  background-color: transparent;
  color: gold;
  border: none;
  font-size: 3rem;
  margin-right: 0.15rem;
  padding-bottom: 0.1rem;
  opacity: 0;
}
.cust_feedbckDiv_btn {
  width: fit-content;
  padding: 1rem 4rem;
  font-size: 1.6rem;
  background-color: #116d6e;
  color: #fff;
  border-radius: 6px;
  border: none;
  text-decoration: none;
}

.cust_feedbckDiv_btn:hover {
  background-color: #28b5b5;
  cursor: pointer;
  font-weight: 600;
}

.cust_feedback_close {
  color: red;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
}
@media only screen and (max-width: 1000px) {
  .cust_Apnt_Itm_head {
    grid-template-columns: 1fr;
  }

  .custAcc_Apnt_Itm_btn {
    width: 100%;
    background-color: #083a56;
    color: #fff;
    padding: 0.2rem 4rem;
    font-size: 2rem;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: none;
  }
}
