@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%; /*1rem = 10px*/
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: 'Josefin Sans', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* icon overwrites */
.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 35;
  vertical-align: bottom;
  margin-right: 0.25rem;
}

.locationIcon {
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

.errorPage {
  display: grid;
  gap: 2rem;
  text-align: center;
  margin: 4rem;
  padding: 4rem;
  color: #cd1818;
}

.errorPage p {
  font-size: 1.5rem;
}
