.branchListItem {
  margin-bottom: 3rem;
}
.itemDetails {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgb(189, 189, 189);
  padding: 2rem;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
}
.itemDetailsLeft {
  grid-column: 1 / 2;
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr;
}
.itemDetailsLeftImg {
  grid-column: 1 / 2;
  grid-row: 1 / 5;
  width: 100%;
  max-height: 20rem;
  object-fit: cover;
  border-radius: 10px;
  border: 4px solid #019267;
}
.itemDetailsLeftTitle {
  grid-column: 2 / 3;
  font-size: 1.8rem;
  font-weight: 500;
  color: #083a56;
  margin-bottom: 0.5rem;
}
.itemDetailsLeftRating {
  grid-column: 2 / 3;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.itemDetailsLeftLocation {
  color: rgb(30 28 28);
  grid-column: 2 / 3;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
.itemDetailsLeftReview {
  grid-column: 2 / 3;
}
.itemDetailsLeftReviewBlock {
  background-color: #116d6e;
  display: inline-block;
  padding: 0.3rem;
  font-size: 1.3rem;
  width: min-content;
  color: #fff;
  border-radius: 2px;
  margin: 0.2rem;
}
.itemDetailsLeftReviewText {
  display: inline-block;
  font-size: 1.6rem;
  color: #116d6e;
}

.itemDetailsRight {
  grid-column: 2 / 3;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr max-content max-content;
  grid-template-rows: repeat(2, min-content) 1fr;
  border-left: 1px solid rgb(231, 231, 231);
  padding-left: 5rem;
}
.itemDetailsRightVenue {
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  font-size: 1.8rem;
  color: #083a56;
}
.itemDetailsRightDesc {
  grid-column: 1 / -1;
  grid-row: 2 / 3;
  line-height: 1.7;
  font-size: 1.6rem;
}

.itemDetailsRighttimingsBtn {
  grid-column: 2 / 3;
  grid-row: 3 / 4;
  background-color: #28b5b5;
  position: relative;
}
.itemDetailsRightBookBtn {
  grid-column: 3 / 4;
  grid-row: 3 / 4;
  background-color: #116d6e;
}

.itemDetailsRighttimingsBtn,
.itemDetailsRightBookBtn {
  padding: 0.7rem 4rem;
  border-radius: 5px;
  border: none;
  font-size: 1.5rem;
  color: #fff;
  width: max-content;
  place-self: end;
  text-decoration: none;
}

.AllBranchItemdropdown {
  width: 2.5rem;
  position: absolute;
  top: 5px;
  right: 4px;
}

.timingSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  background-color: #f2f8f2;
  padding: 1rem 2rem;
  border: 1px solid #a6edee;
  border-radius: 10px;
  margin-top: 1rem;
  font-size: 1.3rem;
  font-weight: 500;
}

.timingList {
  display: grid;
  row-gap: 0.8rem;
  padding: 0 4rem;
}

.timingListItem {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.topServiceslist {
  display: grid;
  row-gap: 1rem;
  list-style: none;
  color: #083a56;
}
.topServicesItem {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.topServicesSelect {
  text-decoration: none;
  color: #019267;
  font-weight: 600;
}

.exploreVenueBtn {
  margin-top: 0.3rem;
  background-color: transparent;
  border: none;
  color: #116d6e;
  font-size: 1.5rem;
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 1500px) {
  .itemDetails {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 1200px) {
  .itemDetails {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .itemDetailsLeft {
    grid-column: 1 / -1;
    display: grid;
    gap: 3rem;
    grid-template-columns: 1fr 2fr;
  }
  .itemDetailsLeftImg {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .itemDetailsRight {
    grid-column: 1 / -1;

    border-left: none;
    padding-left: 0;
  }
}
@media only screen and (max-width: 700px) {
  .timingSection {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr;
  }
  .topServices {
    padding-left: 3.7rem;
  }
}
@media only screen and (max-width: 550px) {
  .itemDetailsLeft {
    grid-column: 1 / -1;
    display: grid;
    gap: 3rem;
    grid-template-columns: 1fr;
  }
}
