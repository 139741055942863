.reviewItem {
  display: grid;
  gap: 1.5rem;
  justify-items: center;
  align-content: start;
  text-align: center;
}
.reviewItemDP {
  width: 12rem;
  height: 12rem;
  object-fit: cover;
  border-radius: 50%;
}

.reviewItemStars {
  display: flex;
}
.reviewItemStar {
  width: 2.3rem;
  height: 2.3rem;
}
.reviewItemStar:not(:last-child) {
  margin-right: 0.5rem;
}

.reviewItemText {
  color: #083a56;
  font-size: 1.25rem;
  line-height: 1.7;
  font-weight: 500;
}

.reviewItemText span {
  color: #a88206;
  margin: 0 0.5rem;
  text-decoration: underline;
  cursor: pointer;
}
