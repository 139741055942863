.rset_pass_container {
  padding: 4rem;
  display: grid;
  justify-content: center;
}
.rset_pass {
  box-shadow: 0px 0px 10px rgb(221, 221, 221);
  padding: 4rem;
  border-radius: 15px;
  display: grid;
  gap: 1rem;
}
.rset_inputDiv {
}
.rset_inputDiv label {
  display: block;
  font-size: 1.4rem;
  margin-bottom: 0.4rem;
}
.rset_inputDiv input {
  padding: 1.5rem;
  width: 50vw;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
}
.rset_inputDiv input:focus,
.rset_inputDiv input:active {
  outline: none;
  border-bottom: 3px solid #116d6e;
}

.rset_inputDiv input::placeholder {
  font-size: 1.5rem;
  color: #5d8191;
}
.rset_button {
  padding: 1rem 4rem;
  font-size: 1.6rem;
  background-color: #116d6e;
  color: #fff;
  border-radius: 6px;
  border: none;
  width: fit-content;
}
