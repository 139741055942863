.thankyou {
  display: grid;
  width: 60%;
  margin: 6rem auto;
  box-shadow: 0px 0px 10px rgb(209, 209, 209);
  border-radius: 15px;
  padding: 1rem;
}

.thankyou_head {
  display: grid;
  gap: 0.2rem;
  justify-content: center;
  justify-items: center;
  background: #116d6e;
  color: #fff;
  padding: 1rem;
  border-radius: 10px;
}
.thankyou_head img {
  width: 6rem;
}
.thankyou_head p {
  font-size: 1.3rem;
}

.thankyou_details {
  list-style: none;
  padding: 4rem;
  font-size: 1.4rem;
  font-weight: 500;
}
.thankyou_details li {
  margin-bottom: 1rem;
  border-bottom: 1px dashed rgb(224, 224, 224);
  padding-bottom: 0.5rem;
}
.thankyou_details_item {
  display: flex;
  justify-content: space-between;
}
