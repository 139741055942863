.quick_serach {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  place-self: center start;
  width: 30rem;
  height: min-content;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  background-color: rgb(217, 217, 217, 0.7);
  padding: 4rem 2.5rem;
  border-radius: 8px;
  margin-left: 7rem;
}

.tabsBox {
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  width: 100%;
  color: #083a56;
  transform: translateY(1.2rem);
}

.tabs {
  width: 100%;
  font-weight: 500;
}

.heroIcons {
  color: #116d6e;
  position: absolute;
  top: 0.8rem;
  left: 0.5rem;
}

.serviceInput,
.locationInput,
.saloonInput {
  position: relative;
  height: 3rem;
  padding: 0.2rem;
  border-radius: 5px;
  border: 1px solid #bdf7f8;
  padding: 0.5rem;
  width: 96%;
  background-color: #fff;
}

.input {
  height: 3rem;
  padding: 0.2rem;
  border-radius: 5px;
  padding: 0.5rem;
  width: 80%;
  transform: translate(2.4rem, -0.65rem);
  background-color: transparent;
  border: none;
}

.input::placeholder {
  color: #116d6e;
}

.input:focus {
  outline: none;
}

.suggetionList {
  position: absolute;
  top: 5rem;
  left: 0;
  z-index: 100;
  width: 93%;
  border-radius: 5px;
  background-color: #dfdfdf;
  list-style: none;
  padding: 1rem;
}

.suggetionItem {
  border-radius: 2px;
  font-size: 1.2rem;
  padding: 0.5rem;
}

.suggetionItem:hover {
  background-color: #fff;
  cursor: pointer;
  color: #116d6e;
}

.suggetionItemActive {
  color: #116d6e;
  background-color: #fff;
}

.homeSearchBtn {
  padding: 0.8rem 0;
  border-radius: 5px;
  background-color: #116d6e;
  border: none;
  font-weight: 400;
  font-size: 1.6rem;
  color: #fff;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .quick_serach {
    width: 25rem;
    padding: 2rem 2rem;
    margin-left: 4rem;
  }
}

@media only screen and (max-width: 780px) {
  .quick_serach {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    place-self: end center;
    width: 70%;
    display: grid;
    margin-left: 0rem;
    transform: translateY(8rem);
  }
}
