.about {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 4rem;
  margin: 3rem 5rem;
}

.aboutDescription {
  grid-column: 1 / 2;
  box-shadow: 0px 0px 10px rgb(214, 214, 214);
  border-radius: 15px;
  padding: 5rem;
}

.aboutDescriptionHead {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.aboutDescriptionPara {
  font-size: 1.5rem;
  line-height: 2;
  padding-right: 4rem;
}

.aboutSideInfo {
  grid-column: 2 / 3;
}

.aboutImg {
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0px 0px 10px rgb(214, 214, 214);
}

.aboutLinks {
  padding: 3rem;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0px 0px 10px rgb(214, 214, 214);
}

.aboutLinks h3 {
  font-size: 1.6rem;
  margin-bottom: 2rem;
}

.aboutLinks ul {
  list-style: none;
  display: grid;
  gap: 1rem;
}

.aboutLinks li a {
  text-decoration: none;
  font-size: 1.3rem;
}

@media only screen and (max-width: 1024px) {
  .about {
    padding-top: 8rem;
  }
}
@media only screen and (max-width: 1000px) {
  .about {
    display: grid;
    grid-template-columns: 1fr;
  }
  .aboutDescription {
    grid-column: 1 / -1;
    padding: 2rem;
  }
  .aboutSideInfo {
    grid-column: 1 / -1;
  }
}
