.allBranches {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: min-content 1fr;
}

.allBranches h1 {
  grid-column: 1 / -1;
  grid-row: 1 /2;
  background-color: #019267;
  font-size: 2.5rem;
  padding: 1.7rem;
  font-weight: 400;
  color: #fff;
}

.branchSorting {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  color: #fff;
  place-self: center;
}

.branchSorting label {
  font-size: 1.5rem;
  margin-right: 1rem;
}

.branchSorting input {
  padding: 0.3rem;
  width: 25rem;
  margin-right: 1rem;
  border-radius: 5px;
  border: none;
}
.branchSorting input:focus {
  outline: none;
}

.branchSorting select:focus {
  outline: none;
}

.branchList {
  grid-column: 1 / -1;
  grid-row: 2 / 3;
  padding: 6rem;
  list-style: none;
  background-color: #dddddd;
  font-family: 'Josefin Sans', sans-serif;
}
@media only screen and (max-width: 1025px) {
  .allBranches h1 {
    margin-top: 5.7rem;
  }
  .branchSorting {
    margin-top: 5.7rem;
  }
}
@media only screen and (max-width: 775px) {
  .branchList {
    padding: 1rem;
  }
}
@media only screen and (max-width: 700px) {
  .allBranches {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content 1fr;
  }

  .allBranches h1 {
    grid-column: 1 / -1;
    grid-row: 1 /2;
  }

  .branchSorting {
    background-color: #019267;
    width: 100vw;
    padding: 1rem;
    grid-column: 1 / -1;
    grid-row: 2 / 3;
    margin-top: 0;
  }

  .branchList {
    grid-column: 1 / -1;
    grid-row: 3 / 4;
  }
}
