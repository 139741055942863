.review {
  display: grid;
  margin-top: 4rem;
}

.review h1 {
  text-align-last: center;
  color: #083a56;
  font-family: 'Josefin Sans', sans-serif;
}

.reviewBrief {
  text-align: center;
  color: #083a56;
  font-size: 1.5rem;
  margin-top: 1.3rem;
}

.reviewItems {
  display: grid;
  grid-template-columns: repeat(auto-fit, 20rem);
  gap: 5rem;
  justify-content: center;
  padding: 8rem;
}
