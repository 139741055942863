.category {
  margin: 0 4rem;
  padding: 4rem;
  box-shadow: 0px 0px 10px rgb(80, 79, 79);
  border-radius: 10px;
  transform: translateY(-3rem);
  background-color: #fff;
  display: grid;
  justify-items: center;
  gap: 1.2rem;
}

.category h1 {
  text-align-last: center;
  color: #083a56;
  font-family: 'Josefin Sans', sans-serif;
}

.categoryBrief {
  text-align: center;
  color: #083a56;
  font-size: 1.5rem;
}

.categoryList {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 12rem);
  justify-content: center;
  justify-items: center;
  gap: 5rem;
  margin: 2rem 0;
}
.categoryListItem {
  list-style: none;
}

.categoryListItemAnchor {
  font-size: 1.5rem;
  text-decoration: none;
  text-align: center;
  color: #116d6e;
}

.categoryListItemAnchor p {
  margin-top: 0.5rem;
}

.categoryListItem a img {
  width: 8.5rem;
  fill: #116d6e;
}

.categoryBtn {
  background-color: transparent;
  border: 2px solid #116d6e;
  border-radius: 5px;
  padding: 0.8rem;
  width: fit-content;
  color: #116d6e;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 800;
}

.categoryBtn:hover {
  transform: translateY(-0.2rem);
  color: #083a56;
}

.categoryBtn span {
  margin-left: 0.2rem;
}

@media only screen and (max-width: 780px) {
  .category {
    margin-top: 10rem;
    transform: translateY(5rem);
  }
}
