.blogList_item_details {
  text-decoration: none;
  color: #116d6e;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: repeat(3, min-content);
  column-gap: 1rem;
  row-gap: 0.5rem;
  box-shadow: 0px 0px 10px rgb(209, 209, 209);
  border-radius: 10px;
  padding: 1rem 0.5rem;
}
.blogList_item_details_title {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4;
}
.blogList_item_details_date {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  font-size: 1.1rem;
  font-weight: 500;
  border-bottom: 1px dashed rgb(160, 219, 160);
  width: max-content;
}
.blogList_item_details_img {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  max-width: 8.5rem;
  border-radius: 5px;
}
.blogList_item_details_desc {
  grid-column: 1 / -1;
  grid-row: 3 / 4;
  font-size: 1.3rem;
  line-height: 1.8;
  padding-top: 1rem;
  color: #8a8a8a;
}
