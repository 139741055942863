/* calender */
.apnt {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 70%;
  margin: 6rem auto;
  box-shadow: 0px 0px 10px rgb(209, 209, 209);
  border-radius: 15px;
}
.apnt_head {
  grid-column: 1 / -1;
  background-color: #116d6e;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 2rem;
  color: #fff;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
}
.apnt_L {
  grid-column: 1 / 2;
  padding: 4rem;
  display: grid;
  gap: 1rem;
}
.apnt_calender {
}
.apnt_calender_head {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  border-radius: 10px;
  text-align: center;
  font-size: 1.8rem;
  color: #019267;
  font-weight: 600;
  border: 1px solid #aceeda;
  box-shadow: 0px 0px 5px rgb(173, 173, 173);
  padding: 2rem;
}
.apnt_calender_head button {
  width: 3.6rem;
  height: 3.6rem;
  box-shadow: 0px 0px 5px rgb(136, 136, 136);
  border-radius: 50%;
  text-align: center;
  font-size: 2rem;
  border: none;
  background-color: #019267;
  color: #fff;
}

.apnt_calender_body {
  box-shadow: 0px 0px 5px rgb(173, 173, 173);
  padding: 1rem;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 4rem);
  gap: 0.7rem;
  justify-content: center;
  margin-top: 1rem;
}

.calenderInactive {
  color: #838383;
  background-color: #fff;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 5px;
  font-weight: 500;
  font-size: 1.5rem;
  height: 4rem;
}
.calenderInactive span {
  display: block;
  font-size: 1.1rem;
}

.calenderActive {
  background-color: #fff;
  border: 1px solid #019267;
  border-radius: 5px;
  font-weight: 500;
  font-size: 1.5rem;
  height: 4rem;
  color: #116d6e;
  cursor: pointer;
}

.calenderActive span {
  display: block;
  font-size: 1.1rem;
  color: #019267;
}

.calenderSuperActive {
  background-color: #019267;
  border: 1px solid #116d6e;
  border-radius: 5px;
  font-weight: 500;
  font-size: 1.5rem;
  height: 4rem;
  color: #ffffff;
  cursor: pointer;
}

.calenderSuperActive span {
  display: block;
  font-size: 1.1rem;
  color: #ffffff;
}

.apnt_Timings {
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid rgb(235, 235, 235);
  font-size: 1.4rem;
  color: #406d86;
  box-shadow: 0px 0px 5px rgb(224, 224, 224);
}
.apnt_Timings:focus,
.apnt_Timings:active {
  border: 1px solid #116d6e;
  outline: none;
}

.apnt_R {
  border-left: 1px dashed rgb(194, 194, 194);
  font-size: 1.4rem;
  font-weight: 600;
  color: #083a56;
  padding: 4rem;
  display: grid;
  grid-template-rows: max-content 1fr max-content;
}
.apnt_R_top {
  grid-row: 1 / 2;
  display: grid;
  gap: 2rem;
}
.apnt_R_top_ListItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed rgb(233, 233, 233);
  padding-bottom: 0.2rem;
  color: #019267;
  font-weight: 500;
  font-size: 1.3rem;
}

.apnt_R_employee {
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid rgb(216, 216, 216);
  font-size: 1.4rem;
  color: #406d86;
  box-shadow: 0px 0px 5px rgb(224, 224, 224);
}

.apnt_R_employee:focus,
.apnt_R_employee:active {
  border: 1px solid #116d6e;
  outline: none;
}
.apnt_R_bottom {
  grid-row: 3 / 4;
  display: grid;
  gap: 2rem;
}
.apnt_R_totals {
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
}
.apnt_R_proceed {
  padding: 0.7rem 4rem;
  background-color: #019267;
  border: none;
  border-radius: 5px;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 500;
}
