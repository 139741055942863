@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}
.spinner {
  position: relative;
  margin: 2rem auto 0.2rem auto;
  z-index: 300;
  display: block;
  content: '';
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 5px solid #019267;
  border-left: 5px solid #083a56;
  animation: rotate 1s linear infinite forwards;
}

.text {
  color: #083a56;
  margin: 0 auto;
  width: fit-content;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
