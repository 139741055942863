.business_pass {
  box-shadow: 0px 0px 10px rgb(231, 231, 231);
  border-radius: 15px;
  width: 60vw;
  margin: 3rem auto;
  padding: 2rem;
}
.business_pass h1 {
  text-align: center;
  color: #116d6e;
}
.business_pass_form {
  display: grid;
  gap: 1rem;
}
.business_pass_Inputs {
  display: grid;
  gap: 0.5rem;
}
.business_pass_Inputs label {
  font-size: 1.5rem;
  font-weight: 500;
}
.business_pass_Inputs input {
  padding: 1.5rem;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
}
.business_pass_Inputs input:focus,
.business_pass_Inputs input:active {
  outline: none;
  border-bottom: 3px solid #116d6e;
}

.business_pass_Inputs input::placeholder {
  font-size: 1.5rem;
  color: #5d8191;
}
.business_pass_btn {
  padding: 1rem 4rem;
  font-size: 1.6rem;
  background-color: #116d6e;
  color: #fff;
  border-radius: 6px;
  border: none;
  width: max-content;
  place-self: end;
}

.business_pass_btn:hover {
  background-color: #28b5b5;
  cursor: pointer;
  font-weight: 600;
}

.passChangeMsgSuccess,
.passChangeMsgFail {
  font-size: 1.4rem;
  font-weight: 500;
}
.passChangeMsgSuccess {
  color: darkgreen;
}
.passChangeMsgFail {
  color: red;
}
