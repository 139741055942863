.branchHero {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content min-content;
}

.branchHeroImg {
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  width: 100%;
  height: 65vh;
  object-fit: cover;
}

.branchIntro {
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  display: grid;
  grid-template-columns: max-content 1fr min-content min-content;
  grid-template-rows: min-content min-content;
  column-gap: 2rem;
  background-color: rgba(255, 255, 255);
  height: min-content;
  width: 100%;
  place-self: end start;
  transform: translateY(-0.4rem);
  padding: 2rem;
  backdrop-filter: blur(5px);
}
.branchIntro h1 {
  grid-column: 1 /2;
  grid-row: 1 / 2;
  font-size: 3rem;
}
.branchIntro_location {
  grid-column: 1 /2;
  grid-row: 2 / 3;
  font-size: 1.2rem;
  font-weight: 500;
}
.branchIntroRating {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  font-size: 1.5rem;
  place-self: end start;
}
.branchIntroRating span {
}
.branchIntroBookBtn,
.branchIntroReviewBtn {
  padding: 0.7rem 4rem;
  border-radius: 5px;
  border: none;
  font-size: 1.7rem;
  color: #fff;
  text-decoration: none;
}
.branchIntroBookBtn {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  background-color: #116d6e;
}
.branchIntroReviewBtn {
  grid-column: 4 / 5;
  grid-row: 1 / 2;
  background-color: #28b5b5;
  margin-right: 5rem;
}
.branchBriefOpen {
  display: inline-block;
  color: #019267;
  margin-left: 2rem;
}
.branchBriefClose {
  display: inline-block;
  color: #cd1818;
  margin-left: 2rem;
}
.branchReviews {
  width: 100%;
  padding: 2rem;
  border-top: 1px solid #116d6e;
}
.branchReviews p {
  font-size: 1.5rem;
  font-weight: 600;
}

@media only screen and (max-width: 1025px) {
  .branchHeroImg {
    margin-top: 8rem;
    width: 100vw;
  }
  .branchIntro {
    display: grid;
    grid-template-columns: max-content min-content;
    grid-template-rows: min-content min-content min-content;
    gap: 1rem;
  }
  .branchIntro h1 {
    grid-column: 1 /2;
    grid-row: 1 / 2;
  }
  .branchIntro_location {
    grid-column: 1 /2;
    grid-row: 2 / 3;
  }
  .branchIntroRating {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
  .branchIntroBookBtn {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    width: fit-content;
  }
  .branchIntroReviewBtn {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    width: fit-content;
    margin-right: 0;
    place-self: end;
    text-decoration: none;
  }
}

@media only screen and (max-width: 600px) {
  .branchIntro {
    display: grid;
    grid-template-columns: max-content;
    grid-template-rows: min-content min-content min-content min-content;
    gap: 1rem;
  }
  .branchIntro h1 {
    grid-column: 1 /2;
    grid-row: 1 / 2;
    font-size: 2.5rem;
  }
  .branchIntro_location {
    grid-column: 1 /2;
    grid-row: 2 / 3;
  }
  .branchIntroRating {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }
  .branchIntroBookBtn {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
    font-size: 1.2rem;
  }
  .branchIntroReviewBtn {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
    font-size: 1.2rem;
  }
}
