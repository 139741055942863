.custAcc_prof_details {
  box-shadow: 0px 0px 10px rgb(231, 231, 231);
  border-radius: 15px;
  width: 60vw;
  margin: 3rem auto;
  padding: 2rem;
}

.custAcc_prof_details h1 {
  text-align: center;
  color: #116d6e;
}
.custAcc_prof_form {
  display: grid;
  gap: 1rem;
}
.custAcc_prof_Inputs {
  display: grid;
  gap: 0.5rem;
}
.custAcc_prof_Inputs label {
  font-size: 1.5rem;
  font-weight: 500;
}
.custAcc_prof_Inputs input {
  padding: 1.5rem;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
}
.custAcc_prof_Inputs input:focus,
.custAcc_prof_Inputs input:active {
  outline: none;
  border-bottom: 3px solid #116d6e;
}

.custAcc_prof_Inputs input::placeholder {
  font-size: 1.5rem;
  color: #5d8191;
}
.custAcc_prof_btn {
  padding: 1rem 4rem;
  font-size: 1.6rem;
  background-color: #116d6e;
  color: #fff;
  border-radius: 6px;
  border: none;
  width: max-content;
  place-self: end;
}

.custAcc_prof_btn:hover {
  background-color: #28b5b5;
  cursor: pointer;
  font-weight: 600;
}

.updateProfMsgSuccess,
.updateProfMsgFail {
  font-size: 1.4rem;
  font-weight: 500;
}
.updateProfMsgSuccess {
  color: darkgreen;
}
.updateProfMsgFail {
  color: red;
}
