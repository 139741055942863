.busDetails {
  margin: 3rem;
  padding: 2rem;
  box-shadow: 0px 0px 10px rgb(231, 231, 231);
  border-radius: 15px;
  display: grid;
  gap: 3rem;
}
.busDetails h1 {
  color: #116d6e;
  text-align: center;
}
.bus_form_sec {
  display: grid;
  gap: 3rem;
}
.bus_forms {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
}
.bus_inputDivs {
  display: grid;
  gap: 0.5rem;
}
.bus_inputDivs label {
  font-size: 1.5rem;
  font-weight: 500;
}
.bus_inputDivs input,
.bus_inputDivs select {
  padding: 1.5rem;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
}
.bus_inputDivs input:focus,
.bus_inputDivs input:active,
.bus_inputDivs select:focus,
.bus_inputDivs select:active {
  outline: none;
  border-bottom: 3px solid #116d6e;
}

.bus_inputDivs input::placeholder {
  font-size: 1.5rem;
  color: #5d8191;
}
.aboutInputDiv,
.addressInputDiv,
.mapInputDiv {
  grid-column: 1 / -1;
}

/* Display pic */
.bus_DP {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;
  gap: 1rem;
}
.bus_DP img {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  width: 25rem;
  border-radius: 5px;
}
.bus_DP h2 {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  color: #116d6e;
  height: fit-content;
}
.bus_DP_inputDiv {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  place-self: start;
  display: grid;
  gap: 0.8rem;
}
.bus_DP_inputDiv label {
  font-size: 1.3rem;
  font-weight: 500;
}
.bus_DP_inputDiv input {
  font-size: 1.3rem;
  font-weight: 500;
  color: #28b5b5;
  border: 1px solid grey;
  border-radius: 5px;
}

.bus_Btn {
  padding: 1rem 4rem;
  font-size: 1.6rem;
  background-color: #116d6e;
  color: #fff;
  border-radius: 6px;
  border: none;
  width: max-content;
  place-self: end;
}

.bus_Btn:hover {
  background-color: #28b5b5;
  cursor: pointer;
}

.responseMSG {
  font-size: 1.3rem;
  font-weight: 500;
  color: #116d6e;
}
@media only screen and (max-width: 1000px) {
  .bus_forms {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
  }
}
@media only screen and (max-width: 560px) {
  .bus_forms {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .bus_DP {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content);
    gap: 1rem;
  }
  .bus_DP img {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    width: 100%;
  }
  .bus_DP h2 {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    height: fit-content;
  }
  .bus_DP_inputDiv {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    place-self: start;
  }
  .bus_Btn {
    place-self: start;
  }
}
