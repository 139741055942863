.register {
  display: grid;
  gap: 5rem;
  background-color: #ebebeb;
  color: #116d6e;
  padding: 2rem;
  justify-content: center;
}

.inputFieldsBox {
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(3, max-content);
  justify-content: center;
  justify-items: center;
  background-color: #fff;
  padding: 4rem;
  box-shadow: 0px 0px 10px rgb(199, 199, 199);
  background-color: #fff;
  border-radius: 20px;
  width: max-content;
}

.registerHead {
  grid-column: 1 / -1;
  text-align: center;
  border-bottom: 1px dashed #dddddd;
  width: 100%;
  padding: 2rem;
}

.registerHead h1 {
  margin-bottom: 0.7rem;
}

.registerHeadlogin {
  font-size: 1.35rem;
  color: #083a56;
  cursor: pointer;
}

.inputFields label {
  display: block;
  font-size: 1.4rem;
  margin-bottom: 0.4rem;
}

.inputFields input {
  padding: 1.5rem;
  width: 28rem;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
}

.inputFields select {
  padding: 1.5rem;
  width: 30rem;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
}

.inputFields input:focus,
.inputFields input:active {
  outline: none;
  border-bottom: 3px solid #116d6e;
}

.inputFieldsAddress {
  grid-column: 1 / 3;
  width: 98%;
}
.inputFieldsAddress label {
  display: block;
  font-size: 1.4rem;
  margin-bottom: 0.4rem;
}
.inputFieldsAddress input {
  padding: 1.5rem;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
  width: 67rem;
}
.inputFieldsAddress input:focus,
.inputFieldsAddress input:active {
  outline: none;
  border-bottom: 3px solid #116d6e;
}

.regiterBtn {
  grid-column: 1 / 2;
  padding: 1rem 4rem;
  font-size: 1.6rem;
  background-color: #116d6e;
  color: #fff;
  border-radius: 6px;
  border: none;
  width: 100%;
}
.regiterBtn:hover {
  background-color: #28b5b5;
  cursor: pointer;
  font-weight: 600;
}
.regiterCancelBtn {
  grid-column: 2 / 3;
  padding: 1rem 4rem;
  font-size: 1.6rem;
  background-color: #116d6e;
  color: #fff;
  border-radius: 6px;
  border: none;
  width: 25rem;
  text-decoration: none;
  text-align: center;
}
.regiterCancelBtn:hover {
  background-color: #28b5b5;
  cursor: pointer;
  font-weight: 600;
}
.regiterError,
.finalMsg {
  grid-column: 1 / -1;
  font-size: 1.5rem;
  font-weight: 500;
}
