.signup {
  padding: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  justify-content: center;
  justify-items: center;
  background-color: #ebebeb;
  color: #116d6e;
}

.signupCust {
  grid-column: 1 / 2;
  place-self: center end;
  display: grid;
  justify-items: center;
  gap: 2rem;
  box-shadow: 0px 0px 10px rgb(199, 199, 199);
  background-color: #fff;
  padding: 5rem;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.signupBusiness {
  grid-column: 2 / 3;
  place-self: center start;
  display: grid;
  justify-items: center;
  gap: 2rem;
  box-shadow: 0px 0px 10px rgb(199, 199, 199);
  background-color: #fff;
  padding: 5rem;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.nameInput,
.emailInput,
.passInput,
.mobileInput {
  position: relative;
}

.signupInputs {
  padding: 1.5rem;
  width: 30rem;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
}
.signupInputs:focus,
.signupInputs:active {
  outline: none;
  border-bottom: 3px solid #116d6e;
}

.signupInputs::placeholder {
  font-size: 1.5rem;
  color: #5d8191;
}
.validationErrors {
  position: absolute;
  top: 5rem;
  left: 0;
  color: red;
  font-weight: 600;
}
.signupBtns {
  padding: 1rem 4rem;
  font-size: 1.6rem;
  background-color: #116d6e;
  color: #fff;
  border-radius: 6px;
  border: none;
  text-decoration: none;
}

.signupBtns:hover {
  background-color: #28b5b5;
  cursor: pointer;
  font-weight: 600;
}

.errorBox,
.successBox {
  position: relative;
  width: 100%;
  height: 2rem;
}
.signingUpError {
  color: red;
  font-size: 1.3rem;
  text-align: center;
  font-weight: 500;
  position: absolute;
  top: 0;
  left: 0;
}
.signingUpSuccess {
  color: darkgreen;
  font-size: 1.3rem;
  text-align: center;
  font-weight: 500;
  position: absolute;
  top: 0;
  left: 0;
}
@media only screen and (max-width: 950px) {
  .signup {
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .signupCust {
    grid-column: 1 / -1;
    margin-top: 7rem;
    place-self: center;
    border-radius: 20px;
  }

  .signupBusiness {
    grid-column: 1 / -1;
    place-self: center;
    border-radius: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .signupInputs {
    padding: 1.5rem;
    width: 20rem;
    border-radius: 5px;
    border: 1px solid #dfdfdf;
  }
}
