.confirmation {
  width: 100%;
  height: 80vh;
  text-align: center;
  padding: 4rem;
}
.confirm_infoDiv {
  box-shadow: 0px 0px 5px rgb(223, 223, 223);
  width: 50%;
  margin: 4rem auto;
  padding: 5rem;
  border-radius: 15px;
}

.confirm_infoMsg {
  font-size: 2rem;
  color: rgb(17, 88, 168);
}
.confirm_login {
  font-size: 1.4rem;
  text-decoration: underline;
  color: #116d6e;
  margin-top: 2rem;
  cursor: pointer;
}
