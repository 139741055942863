.popbrands {
  display: grid;
  margin-top: 2rem;
}

.popbrands h1 {
  text-align-last: center;
  color: #083a56;
  font-family: 'Josefin Sans', sans-serif;
}

.brandsBrief {
  text-align: center;
  color: #083a56;
  font-size: 1.5rem;
  margin-top: 1.3rem;
}

.popbrandsList {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, 18rem);
  justify-content: center;
  justify-items: center;
  gap: 7rem;
}

.popbrandsListItem {
  list-style: none;
}

.popbrandsListItemsAnchor {
  font-size: 1.5rem;
  text-decoration: none;
  text-align: center;
  color: #116d6e;
}

.popbrandsListItemsAnchor p {
  margin-top: 0.5rem;
}

.popbrandsListItemsAnchor img {
  width: 18rem;
  height: 18rem;
  border: 4px solid #28b5b5;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  object-fit: cover;
}

.popbrandsBtn {
  border: none;
  background-color: transparent;
  color: #116d6e;
  cursor: pointer;
  transition: all 0.2s;
  width: 100%;
  width: 18rem;
  height: 18rem;
  border: 4px solid #28b5b5;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  font-size: 2rem;
}

.popbrandsBtn:hover {
  transform: translateY(-0.2rem);
  color: #083a56;
  background-color: rgb(17, 109, 110, 0.2);
}

.popbrandsBtn span {
  margin-left: 0.2rem;
}
