.plans {
  margin: 4rem;
  padding: 0 4rem;
  user-select: none;
}

.plans h1 {
  margin-bottom: 4rem;
  text-align: center;
}

.plansBox {
  display: grid;
  grid-template-columns: repeat(auto-fit, 35rem);
  gap: 4rem;
  justify-content: center;
}

.plan {
  border-radius: 10px;
  box-shadow: 0px 0px 8px rgb(190, 190, 190);
}

.planHeadImg {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-image: linear-gradient(
      to right,
      rgb(17, 109, 110, 0.9),
      rgb(1, 146, 103, 0.6)
    ),
    url('/public/assets/images/general/plansHead.jpg');
  height: 6rem;
  background-position: center;
  background-size: cover;
  display: grid;
  align-content: center;
}

.planHeadImg span {
  color: white;
  font-size: 2.2rem;
  font-weight: 500;
  text-shadow: 0px 0px 5px black;
  text-align: center;
}

.planFeatureList {
  list-style: none;
  padding: 2rem;
  height: 69%;
}

.planFeature {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2rem;
}

.subscribeRate {
  background-color: #28b5b5;
  text-align: center;
  font-size: 2rem;
  padding: 1rem;
  color: #fff;
  font-weight: 600;
}

.subscribeBtn {
  background-color: #116d6e;
  border: none;
  padding: 1rem;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #fff;
  font-size: 1.7rem;
  font-weight: 500;
}
