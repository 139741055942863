/* service section */
.serviceSec {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1.5fr 1fr;
  padding: 2rem 6rem;
}
.services {
  display: grid;
  gap: 2rem;
}
.services h3 {
  font-size: 2rem;
  font-weight: 500;
}
.serviceList {
  list-style: none;
}

.orders {
  display: grid;
  gap: 2rem;
  padding: 0 2rem;
  border-left: 1px solid rgb(226, 226, 226);
}

.orders h3 {
  font-size: 2rem;
  font-weight: 500;
}
.orderBox {
  box-shadow: 0px 0px 10px rgb(185, 185, 185);
  border-radius: 10px;
  padding: 3rem;
}
.orderHead {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  font-size: 1.4rem;
  border-bottom: 1px solid #019267;
  padding-bottom: 0.5rem;
}

.orderBody {
  display: grid;
}
.orderList {
  display: grid;
  grid-template-columns: 2fr 1fr min-content;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px dashed #e4e4e4;
  font-size: 1.3rem;
}
.orderName {
}
.orderCurrency {
}
.orderDelBtn {
  background-color: #ffffff;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  border: none;
  box-shadow: 0px 0px 2px rgb(150, 150, 150);
  position: relative;
  cursor: pointer;
  color: #7e7e7e;
  place-self: end;
}

.orderDelBtn span {
  font-size: 1.7rem;
}

.orderDelBtn:hover {
  color: #cd1818;
  box-shadow: 0px 0px 2px #cd1818;
}

.orderFoot {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1rem 0;
  width: 100%;
}

.orderTotalAmount {
}

.orderBookBtn {
  padding: 0.7rem 4rem;
  border-radius: 5px;
  border: none;
  font-size: 1.7rem;
  color: #fff;
  background-color: #019267;
  height: min-content;
}

@media only screen and (max-width: 980px) {
  .serviceSec {
    gap: 3rem;
    grid-template-columns: 1fr;
  }

  .orders {
    padding: 0;
    border-left: none;
  }
}
