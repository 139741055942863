.footer {
  display: grid;
  position: relative;
}

.section1 {
  display: grid;
  grid-template-columns: repeat(auto-fit, 25rem);
  gap: 5rem;
  justify-content: center;
  background-color: #019267;
  padding: 6rem;
}

.sec1Items {
  display: grid;
  gap: 0.8rem;
  justify-items: center;
  text-align: center;
  color: #fff;
}
.sec1Items img {
  width: 25%;
}
.sec1Items h4 {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
}
.sec1Items p {
  width: 100%;
  font-size: 1.3rem;
}

.section2 {
  display: grid;
  padding: 4rem;
}

.payAndSubs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.payDiv {
  display: grid;
  gap: 1.2rem;
}

.payDiv p {
  font-size: 1.5rem;
}

.paymentImagesBox {
  display: grid;
  grid-template-columns: repeat(auto-fit, 6rem);
  column-gap: 1.2rem;
  align-items: center;
}

.paymentImages {
  width: 100%;
}

.subscribeDiv {
  display: grid;
  gap: 1.2rem;
  justify-content: center;
  opacity: 0;
}

.subscribeDiv p {
  font-size: 1.5rem;
  text-align: center;
}

.subscribeDivInput input {
  border: 1px solid #083a56;
  padding: 1rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.subscribeDivInput input:focus {
  outline: none;
}

.subscribeDivInput label {
  background-color: #083a56;
  padding: 1rem 2rem 1.1rem 1rem;
  font-size: 1.3rem;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
}

.keepTouchDiv {
  display: grid;
  gap: 1.2rem;
}

.keepTouchDiv p {
  font-size: 1.5rem;
  text-align: right;
}
.keepTouchImgBox {
  display: grid;
  grid-template-columns: repeat(auto-fit, 3.8rem);
  justify-content: end;
  gap: 2rem;
}
.keepTouchImg {
  width: 100%;
  background-color: #8b8b8b;
  border-radius: 5px;
}

.legals {
  display: grid;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
  text-align: center;
  border-top: 1px dashed rgb(223, 223, 223);
  padding-top: 1rem;
}
.footLinks {
  display: flex;
  list-style: none;
}

.footLinks li {
  text-decoration: underline;
  font-size: 1.2rem;
  margin-right: 1rem;
  color: #083a56;
}

.footLinks li a {
  text-decoration: underline;
  font-size: 1.2rem;
  color: #083a56;
}
.footLinks li a:active {
  color: #019267;
}
.footer_Jumper {
  position: fixed;
  bottom: 2rem;
  right: 3rem;
  z-index: 250;

  background-color: rgb(1, 146, 103, 0.45);
  border: 1px solid #019267;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  text-decoration: none;
}
.footer_Jumper:hover {
  background-color: rgb(1, 146, 103, 1);
  cursor: pointer;
}
.footer_Jumper p {
  font-size: 4.5rem;
  color: #fff;
  text-align: center;
  transform: translateY(1rem);
}

@media only screen and (max-width: 1200px) {
  .payAndSubs {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
  }
  .payDiv {
    display: grid;
    gap: 1.2rem;
    width: 100%;
  }
  .payDiv p {
    font-size: 1.5rem;
    text-align: center;
  }

  .paymentImagesBox {
    display: grid;
    grid-template-columns: repeat(auto-fit, 6rem);
    column-gap: 1.2rem;
    align-items: center;
    justify-content: center;
  }
  .subscribeDiv {
    display: grid;
    gap: 1.2rem;
    justify-content: center;
  }
  .keepTouchDiv {
    display: grid;
    gap: 1.2rem;
  }
  .keepTouchDiv p {
    text-align: center;
  }
  .keepTouchImgBox {
    display: grid;
    grid-template-columns: repeat(auto-fit, 3.8rem);
    justify-content: center;
    gap: 2rem;
  }
}
