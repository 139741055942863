.order {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 70%;
  margin: 6rem auto;
  box-shadow: 0px 0px 10px rgb(209, 209, 209);
  border-radius: 15px;
}

.oder_head {
  grid-column: 1 / -1;
  background-color: #116d6e;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 2rem;
  color: #fff;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 500;
}

.order_body_L {
  grid-column: 1 / 2;
  padding: 4rem;
  display: grid;
  gap: 2rem;
  font-size: 1.4rem;
  font-weight: 500;
}
.order_body_L_child {
  display: flex;
  justify-content: space-between;
}
.order_body_R {
  grid-column: 2 / 3;
  padding: 4rem;
  display: grid;
  gap: 2rem;
  font-size: 1.4rem;
  font-weight: 500;
  border-left: 1px dashed rgb(221, 221, 221);
}
.order_body_R_details {
  border-bottom: 1px dashed rgb(228, 228, 228);
}

.order_body_R_child {
  display: flex;
  justify-content: space-between;
}

.order_body_bottom {
  grid-column: 1 / -1;
  box-shadow: 0px 0px 5px rgb(211, 211, 211);
  border-radius: 10px;
  width: 80%;
  margin: 2rem auto;
  padding: 3rem;
  display: grid;
  gap: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
}
.order_body_bottom_child {
  display: flex;
  justify-content: space-between;
}
.orderSummaryBtn {
  padding: 0.7rem 4rem;
  background-color: #019267;
  border: none;
  border-radius: 5px;
  font-size: 1.6rem;
  color: #fff;
  font-weight: 500;
}
